export const fontSizes = {
  h1: "48px",
  h2: "44px",
  h3: "32px",
  h4: "30px",
  h5: "22px",
  h6: "20px",
  hBig: "56px",
  title: {
    lrg: "22px",
    sml: "20px",
  },
  body: {
    lrg: "24px",
    reg: "18px",
    med: "16px",
    sml: "14px",
    smallest: "12px",
  },
  button: "18px",
}
