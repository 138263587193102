exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-biometric-information-privacy-policy-js": () => import("./../../../src/pages/biometric-information-privacy-policy.js" /* webpackChunkName: "component---src-pages-biometric-information-privacy-policy-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fullspecs-js": () => import("./../../../src/pages/fullspecs.js" /* webpackChunkName: "component---src-pages-fullspecs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pilot-js": () => import("./../../../src/pages/pilot.js" /* webpackChunkName: "component---src-pages-pilot-js" */),
  "component---src-pages-platform-terms-of-use-js": () => import("./../../../src/pages/platform-terms-of-use.js" /* webpackChunkName: "component---src-pages-platform-terms-of-use-js" */),
  "component---src-pages-preorder-js": () => import("./../../../src/pages/preorder.js" /* webpackChunkName: "component---src-pages-preorder-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-reserve-js": () => import("./../../../src/pages/reserve.js" /* webpackChunkName: "component---src-pages-reserve-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

