export const screenBreakpoints = {
  desktop: 1440,
  xlarge: 1280,
  large: 992,
  desktopSmallMax: 1439,
  desktopSmallMin: 1024,
  tabletMax: 1023,
  tabletMin: 768,
  mobileBig: 480,
  mobileMax: 767,
}
