import { colors } from "./colors"
import { fontSizes } from "./fontSizes"
import { fontWeights } from "./fontWeights"
import { screenBreakpoints } from "./screenBreakpoints"

export const theme = {
  colors,
  media: {
    desktop: "@media (min-width: 1024px)",
    tablet: "@media (min-width: 720px)",
    mobile: "@media (max-width: 425px)",
  },
  fontWeights,
  fontSizes,
  screenBreakpoints,
}
